import { finalize, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { objToString } from './stringUtils';

export type SharedObservableContext = {
  /**
   * By setting `excludedParams` argument to this decorator, you can
   * remove any index of arguments given in to the original function from
   * the key used to identify which version of this observable to share
   *
   * I.E. @SharedObservable() getData('something', counter++)
   * This will return a different key each time, thus not sharing anything.
   *
   * @SharedObservable({excludedParams: 1}) getData('something', counter++)
   * This will exclude the counter part of the observable, so the results from
   * the observable is shared regardless of how the `counter` argument is changed.
   */
  excludedParams?: number[];
  /**
   * If you know the observable will only emit once, but is wrapped in an operator
   * which makes the observable hot, you can flip this switch and force the observable
   * to remove itself from the map after the first emitted value.
   */
  emitOnce?: boolean;
};

/**
 * If you have more than one component calling a function which returns an observable,
 * this will create the observable many times. For http requests, this will result in
 * many requests being made and thus redundant http calls.
 *
 * The SharedObservable will make sure that all calls made simultaneously will only
 * perform 1 http call and all subscribers share the same response.
 * This will also take into account different input parameters and create a new
 * request if the parameters are different.
 *
 * USAGE:
 *
 * @SharedObservable()
 * myFunc(id: number): Observable<any> {
 *   return this.http.get<any>(`/api/someUrl/${id}`);
 * }
 */
export function SharedObservable(context?: SharedObservableContext): MethodDecorator {
  // Usually only holds one entry, but if the function takes in arguments
  // which may change, it can hold multiple versions of this observable.
  const subjects = new Map<string, Observable<unknown>>();
  // Keeps track of how many subscribers we have for each observable
  const observers = new Map<string, number>();

  /*
   * Cleanup function which removes the observable from the map when there are no more subscribers
   */
  const cleanup = (key: string) => {
    observers.set(key, (observers.get(key) ?? 1) - 1);
    const count = observers.get(key) ?? 0;
    if (count <= 0) {
      // Only remove if there are no more subscribers
      subjects.delete(key);
    }
  };

  /*
   * The actual decorator function which will replace the original function
   */
  return (_target: any, _propertyKey: string | symbol, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args: any[]) {
      // Create a key from the arguments given to this function, so that we can
      // identify which version of the observable we should share
      let key = objToString(args).replace("'", '"');

      if (context?.excludedParams != null) {
        key = JSON.stringify(
          context.excludedParams.reduce((acc: any[], idx: number) => {
            acc.splice(idx, 1);
            return acc;
          }, JSON.parse(key)),
        );
      }

      // Check if we have the key for this observable in our map
      if (!subjects.has(key)) {
        // We have no observable for this key yet, so we create one
        const response = new ReplaySubject<unknown>(1);
        const source = originalMethod
          .apply(this, args)
          .pipe(
            finalize(() => cleanup(key)),
            catchError((error) => {
              cleanup(key);
              response.error(error);
              return throwError(() => error);
            }),
          )
          .subscribe(response);

        const resp = response.pipe(
          finalize(() => {
            source.unsubscribe();
            cleanup(key);
          }),
        );

        if (context?.emitOnce) {
          resp.subscribe(() => {
            subjects.delete(key);
          });
        }

        subjects.set(key, resp);
      }

      // Return the cached observable
      observers.set(key, (observers.get(key) ?? 0) + 1);
      return subjects.get(key);
    };
    return descriptor;
  };
}
